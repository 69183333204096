import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class HomeownersInsurance extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Homeowners Insurance - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "Homeowners Insurance helps you protect your Louisiana home by helping repair, replace, or recover what's covered from many different incidents. Contact us for an insurance quote!" }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} imgStyle={{objectPosition: 'bottom'}} alt="Home in Baton Rouge, Louisiana" />
        <Callout
          title="Homeowners Insurance"
          content="Your home isn't just the roof, flooring and other structural elements. It's a place of many memories and personal belongings that should be protected. Homeowners Insurance helps you protect your Louisiana home by helping repair, replace or recover what's covered from many different incidents."
        >
          <div className="servicesVideoWrapper"><div className="videoWrapper"><iframe className="iframe services-video" width="560" height="315" src="https://www.youtube.com/embed/Y5MLLgftTzE?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="Homeowners Insurance: What You Need to Know" /></div></div>
        </Callout>
        <div className="site-body page-content">
          <h2>What Homeowners Insurance Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Structure of your home.</strong> Your homeowners policy pays to repair or rebuild your home if it is damaged or destroyed by fire, hurricane, hail, lightning or other disasters listed in your policy. Most policies also cover detached structures such as a garage, tool shed or gazebo— generally for about 10 percent of the amount of insurance you have on the structure of the house.</li>
          <li><strong>Personal belongings.</strong> Your furniture, clothes, sports equipment and other personal items are covered if they are stolen or destroyed by fire, hurricane or other insured disasters. The coverage is generally 50 to 70 percent of the insurance you have on the structure of the house.</li>
          <li><strong>Liability protection.</strong> Liability covers you against lawsuits for bodily injury or property damage that you or family members cause to other people. It also pays for damage caused by your pets.</li>
          <li><strong>Additional living expenses (ALE).</strong> ALE pays the additional costs of living away from home if you cannot live there due to damage from an insured disaster. It covers hotel bills, restaurant meals and other costs, over and above your usual living expenses, incurred while your home is being rebuilt.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="In 2014, 5.3 percent of insured homes had a claim. Property damage, including theft, accounted for 97.3 percent of those claims."
            source="Insurance Information Institute (III)"
          />
          <h2>What Homeowners Insurance Usually Does Not Cover</h2>
          <p>
          <ul>
          <li><strong>Earthquake and water damage.</strong> These can be purchased as separate policies or endorsements.</li>
          <li><strong>Maintenance issues.</strong> Damage resulting to your home from lack of maintenance is not covered.</li>
          <li><strong>Personal damage.</strong> Protection from lawsuits from nonphysical injury such as defamation and slander are not covered, but can be purchased separately.</li>
          <li><strong>Wear & Tear.</strong> Normal wear & tear to your home including roofs and siding are not covered.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Homeowners Insurance" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default HomeownersInsurance;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "homeowners-insurance-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-24.jpg" }) {
      ...mainBg
    }
  }
`;
